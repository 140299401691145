<template>
  <div class="all-schedules">
    <v-container>
      <ScheduleViewOptions />

      <div v-if="allAuthorSchedules" class="all-schedules__wrapper">
        <div
          v-for="(schedule, i) in allAuthorSchedules"
          :key="`schedule-${i}`"
          class="all-schedules__single-wrapper"
          :style="i < allAuthorSchedules.length - 1 ? 'page-break-after: always' : ''"
        >
          <ScheduleSingle
            :targetAuthorData="schedule.authorData"
            :events="schedule.scheduleData"
            :ambassador="schedule.ambassadorData"
            :eventHeaders="eventHeaders(false, schedule.authorData, false)"
            :formattedDateRange="formattedDateRange"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions'
import { mapState, mapGetters, mapActions } from 'vuex'
import ScheduleViewOptions from '@/components/ScheduleViewOptions'
import ScheduleSingle from '@/components/ScheduleSingle'

export default {
  name: 'AllSchedules',
  components: {
    ScheduleViewOptions,
    ScheduleSingle
  },
  data: () => ({
    allAuthorSchedules: []
  }),
  computed: {
    ...mapState(['fbFunctions']),
    ...mapGetters([
      'allAuthors',
      'eventHeaders',
      'formattedDateRange'
    ])
  },
  methods: {
    ...mapActions(['authorsListener'])
  },
  async mounted () {
    setTimeout(async () => {
      const res = await this.authorsListener()
      console.log(res)
      if (this.allAuthors) {
        for (let i = 0; i < this.allAuthors.length; i++) {
          const res = await httpsCallable(this.fbFunctions, 'getMyEvents')({ authorId: this.allAuthors[i].id })
          const amRes = await httpsCallable(this.fbFunctions, 'getMyAmbassador')({ authorId: this.allAuthors[i].id })
          this.allAuthorSchedules.push({
            authorData: this.allAuthors[i],
            scheduleData: res.data,
            ambassadorData: amRes.data
          })
        }
      }
    }, 0)
  }
}
</script>

<style lang="scss">
.all-schedules {
  &__wrapper {
    .v-data-table {
      margin-top: 20px;
      // Hides the search field
      > .v-input:first-child {
        display: none;
      }
    }
  }
}
</style>
